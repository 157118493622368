import { supportEmailAddress } from '@/utils/config';

/* eslint-disable max-lines */
const translation = {
  generateReport: 'Generer rapport',
  chooseReportProps: 'Vælg hvilke bygninger og vedligeholdelsesplan der skal med i rapporten',
  buildingsToDownloadReportFor: 'Bygninger der skal med i rapporten',
  maintenancePlanForReport: 'Vedligeholdelsesplan der skal med i rapporten',
  reportSendingFailed: 'Der skete en fejl ved indsendelse af rapporten. Prøv igen senere.',
  reportFor: 'Rapport for: ',
  reportSentToEmail: 'Rapporten bliver sendt til din email. Det kan tage op til 1 minut før den er klar.',
  buildingNumber: 'Bygningsnummer',
  bbrUuid: 'BBR UUID',
  buildingInfo: 'Bygningsinfo',
  plan: 'Planlæg',
  m2: 'm²',
  runningMeter: 'løbende meter',
  n: 'stk',
  amountQuestion: 'Mængde',
  maybeMartinCanHelp: 'Alternativt kan Martin måske hjælpe dig?',
  failedToUpdatePredictions: 'Det lykkedes ikke at opdatere forudsigelserne',
  materialQuestion: 'Materiale',
  updatingPredictions: 'Opdaterer forudsigelser',
  noRecommendedImprovements: 'Vi har ingen anbefalinger til forbedringer endnu.',
  recommendedSustainabilityActivities: 'Anbefalede forbedringer',
  gCo2PerPerDKKSaved: 'g CO₂/år/DKK',
  tonCo2PerYear: 'ton CO₂/år',
  co2Saving: 'Spar',
  createdAt: 'Oprettet',
  lastSignIn: 'Sidst logget ind',
  signedUp: 'Bruger oprettet',
  dontKnow: 'Ved ikke',
  installationYearQuestion: 'Installations år',
  buildingElementBasicInfo: 'Basal information',
  improvementSuggestionNotice:
    'Prøv lige at check det ud! Der er kommet forbedringer fra energimærkerapporter i appen. 🎉🎉👏👏',
  recommended: 'Anbefalet',
  profitable: 'Rentabelt',
  removeBuildingElement: 'Fjern bygningsdel',
  removeBuildingElementDescription: 'Vil du slette denne bygningsdel?',
  externalLink: 'Link til energimærke',
  improvementLegendTooltip:
    'Forbedringer der blandt andet kan hjælpe med energi forbrug. Dette kan også være forbedringer fra energimærke rapporter.',
  noBuildingsFound: 'Ingen bygninger fundet',
  createNewPassword: 'Lav nyt password',
  resetPasswordEmailSent: 'Password gendannelses email er sendt. Check din inbox.',
  resetPasswordDescription:
    'Indtast din email nedenfor. Hvis din email er knyttet til en bruger, vil vi sende en email med et link til gendannelse af dit password. Følg linket og opret et nyt password.',
  resetPassword: 'Gendan password',
  sendResetPasswordRequest: 'Anmod om gendannelse af password',
  forgotPassword: 'Glemt password?',
  noImagesTaken: 'Der er ikke taget nogen billeder af ejendommen endnu.',
  photos: 'Billeder',
  defaultPlanLength: 'Standard længde af vedligeholdsplan (år)',
  deletePlan: 'Slet plan',
  planDeleted: 'Planen er slettet',
  failedToDeletePlan: 'Det lykkedes os ikke at slette planen',
  deletingPlan: 'Sletter plan',
  copyPlan: 'Dupliker plan',
  areYouSureYouWantToDeletePlanDescription:
    'Er du sikker på at du vil slette planen og alle dets aktiviteter? Det kan ikke fortrydes.',
  areYouSureYouWantToDeletePlan: 'Er du sikker på at du vil slette planen?',
  startYear: 'Start år',
  planLengthYears: 'Planens længde i år',
  around: 'Omkring',
  BBRUpdatedAt: 'BBR sidst opdateret',
  predictionsUpdatedAt: 'Forudsigelser sidst opdateret',
  now: 'Nu',
  daysAgo: 'Dage siden',
  CRONMonitor: 'CRON monitor',
  noCRONDataPoints: 'Kunne ikke finde nogen datapunkter for CRON jobbet.',
  failedToDownloadExcelSheet:
    'Det lykkedes ikke at downloade dataen for ejendommen. Prøv igen senere. Kontakt support hvis problemet varer ved.',
  preventiveActivities: 'Forebyggende aktiviteter',
  restorativeActivities: 'Oprettende aktiviteter',
  remedialActivities: 'Afhjælpende aktiviteter',
  improvementActivities: 'Forbedrende aktiviteter',
  predictedPreventiveActivities: 'Forudset forebyggende aktiviteter',
  predictedRestorativeActivities: 'Forudset oprettende aktiviteter',
  amountType: 'Mængde type',
  amount: 'Mængde',
  installationYear: 'Installationsår',
  material: 'Materiale',
  FVKCode: 'FVK kode',
  buildingBBRUuid: 'Bygnings BBR UUID',
  downloadExcelExport: 'Download al data for ejendommen',
  noRegistrationsStatus: 'Der er ikke oprettet registreringer på ejendommen',
  registrationIsFinsihed: 'Registreringer på ejendommen er gennemført',
  registrationInProgress: 'Der er igangværende registreringer på ejendommen',
  statusForregistrationTooltip: 'Status for registreringer på ejendommen',
  updating: 'Opdaterer',
  unknown: 'Ukendt',
  confidenceScoreRange: 'Sikkerhedsscore spænd',
  noSuggestionsForSearch: 'Der er ikke nogen foreslag der matcher dine søgekriterier.',
  status: 'Status',
  notStarted: 'Ikke startet',
  close: 'Luk',
  deletingProperty: 'Sletter ejendom',
  deleteThisPropertyDescription:
    'Hvis du sletter ejendommen vil alle udførste registreringer og vedligeholdsplaner også blive slettet. Det kan ikke fortrydes. Er du sikker på at du vil fortsætte?',
  noRegistrationsForThisSearch: 'Ingen registreringer fundet for denne søgning.',
  reset: 'Nulstil',
  propertyFilterTitle: 'Filtrer ejendomme',
  confirmSubmitRegistration: 'Er du sikker på at du vil indsende registreringen?',
  confirmSubmitRegistrationDescription:
    'Når du indsender registreringen kan du ikke lave om i dine valg. Registreringen kan heller ikke slettes. Er du sikker på at du vil indsende den? Alternativt kan du gemme den til senere, hvis du stadig vil lave ændringer.',
  propertyRenamed: 'Ejendommen er omdøbt',
  rename: 'Omdøb',
  renameProperty: 'Omdøb ejendom',
  inspected: 'Besigtiget',
  registration: 'Registrering',
  report: 'Rapport',
  uploadTemplate: 'Upload ejendoms import skabelon',
  done: 'Færdig',
  assignedTo: 'Tildelt',
  inProgress: 'I gang',
  finishedAt: 'Færdiggjort',
  rowsSelected: 'rækker valgt',
  rowSelected: 'række valgt',
  reminderSent: 'Påmindelse sendt',
  emailHasDeadline: 'Registreringen har deadline:',
  sendReminder: 'Send påmindelse',
  confirmReminder: 'Er du sikker på at du ville sende en påmindelse?',
  confirmReminderDescription:
    'Er du sikker på at du ville sende en email påmindelse til brugeren. Påmindelsen vil oplyse dem om at de stadig mangler at udføre registreringen.',
  registrationTitle: 'Titel på registrering',
  deadline: 'Deadline',
  deleteThisRegistrationDescription:
    'Er du sikker på at du vil slette denne registrering? Det vil også slette billeder og aktiviteter opsamlet i forbindelse med registreringen.',
  estimatingCosts: 'Genererer forudsigelser om omkostninger',
  calculatingPredictionScores: 'Beregner forudsigelses scorer',
  structuringPredictions: 'Strukturerer forudsigelser',
  of: 'af',
  startingDataCollection: 'Starter indsamling af data',
  gatheringCredentials: 'Samler rettigheder og meta data',
  structuringInformation: 'Strukturerer ejendomsdata',
  fetchingBBRData: 'Indsamler offentlig ejendomsdata',
  structuringBBRData: 'Strukturerer offentlig ejendomsdata',
  generatingPredictions: 'Genererer forudsigelser om stand for ejendom',
  assessingPredictions: 'Validerer forudsigelser',
  loadingPropertyPage: 'Indlæser ejendomssiden',
  suggestionsInfo:
    'Her vises en liste over forslag til registreringer. Hvis en ejendom har en samlet sikkerhedsscore på under 80%, anbefaler vi at oprette en registrering for at øge sikkerhedsscoren. Forslaget til registreringen er præudfyldt med de bygningsdele, der udgør den mindste nødvendige mængde for at hæve den samlede sikkerhedsscore til over 80% for ejendommen. Listen er sorteret efter ejendomme med laveste sikkerhedsscore. Hvis en ejendom har åbne registreringer, foreslår vi ikke at oprette flere.',
  noSuggestions: 'Der er ikke nogen foreslag',
  buildingElements: 'bygningsdele',
  outOf: 'ud af',
  registrationSuggestions: 'Forslag til registreringer',
  informationAbout: 'Information om',
  energyLabelsForBuildings: 'Energimærkninger for dine bygninger',
  noEnergyLabelInfo: 'Ingen energimærkninger fundet',
  noOutStandingActivities: 'Der er ikke flere udestående aktiviteter',
  lastUpdatedAt: 'Sidst opdateret',
  cloudVersion: 'Cloud version',
  localVersion: 'Lokal version',
  chooseRegistration: 'Vælg registrering',
  choose: 'Vælg',
  chooseRegistrationVersionTitle: 'Vælg hvilken registrering du vil fortsætte med',
  chooseRegistrationVersionText:
    'Det ser ud til at der er en registrering gemt på dit device, der er forskellig fra den vi har gemt. Hvilken vil du fortsætte med? Vær opmærksom på at dit valg ikke kan gøres om. Den anden registrering vil blive slettet.',
  saved: 'Gemt',
  syncing: 'Synkroniserer',
  help: 'Hjælp',
  ifCustomerIsInUseThisMightHaveImpact:
    'Hvis du laver ændringer til denne liste kan det have uforudsete konsekvenser hvis denne kunde allerede er i gang med at bruge systemet.',
  buildingElementsInUse: 'Bygningsdele der er i brug på denne kunde',
  customerUpdated: 'Kunden er blevet opdateret',
  customerDeleted: 'Kunden er blevet slettet',
  editCustomerTitle: 'Editer kunden',
  deleteCustomerDescription:
    'Vil du slette denne kunde? Alt data relateret til kunden vil blive slettet. Det gælder både brugere, registreringer og vedligeholdsplaner. Denne handling kan ikke fortrydes.',
  deleteCustomer: 'Slet kunden',
  downloadPropertyImportTemplate: 'Download ejendoms import skabelon',
  propertiesCreated: 'Ejendommene er blevet importeret og oprettet.',
  downloadPropertyList: 'Download excel udtræk af ejendomme',
  lastBBRUpdate: 'Sidste BBR opdatering',
  lastPublicPropertyInfoUpdate: 'Sidste ejendomsdata opdatering',
  lastPredictions: 'Sidste forudsigelser',
  updatePredictions: 'Opdater forudsigelser',
  needHelp: 'Har du brug for hjælp?',
  needHelpDescription: 'Hvis du har spørgsmål, problemer eller brug for hjælp, kan du række ud til os på:',
  deleteUserDescription:
    'Er du sikker på at du vil slette brugeren? Dette vil også slette alle de registreringer brugeren har udført. Dette kan ikke fortrydes!',
  upload: 'Upload',
  failedToFindAddress: 'Kunne ikke finde en adresse',
  failedToFindBFENumber: 'Kunne ikke finde BFE nummer for',
  failedToFindOrganizationLevel: 'Kunne ikke finde organisations niveauer',
  failedToFindParentOrganization: 'Kunne ikke finde moder organisation',
  generatingReport: 'Genererer rapport',
  updatingRegistrationList: 'Opdaterer listen af registreringer',
  registrationCreated: 'Registreringen er oprettet',
  confirmSignUp: 'Bekræft sign up',
  propertyDeleted: 'Ejendommen er slettet',
  deleteThisProperty: 'Er du sikker på at du vil slette denne ejendom?',
  registrationDeleted: 'Registreringen er slettet',
  deleteThisRegistration: 'Vil du slette denne registrering?',
  customerAdded: 'Organisationen er tilføjet',
  noOrganizationsFound: 'Ingen organisationer blev fundet',
  addOrganization: 'Tilføj organisation',
  emailLinkExpired: 'Dit email link er udløbet. Kontakt ' + supportEmailAddress + ' for at få et nyt.',
  passwordSet: 'Dit password er oprettet!',
  somthingWentWrong: 'Noget gik galt. Prøv igen.',
  submit: 'Godkend',
  repeat: 'Gentag',
  createPassword: 'Vælg password',
  userInvited: 'Brugeren er inviteret',
  userSaved: 'Ændringerne er gemt',
  userDeleted: 'Brugeren er slettet',
  selectRole: 'Vælg rolle',
  selectCustomer: 'Vælg kunde',
  invite: 'Inviter',
  inviteUser: 'Inviter bruger',
  editUser: 'Rediger bruger',
  deleteUser: 'Slet bruger',
  customer: 'Kunde',
  type: 'Type',
  noUsersFound: 'Vi fandt ingen brugere',
  admin: 'Admin',
  number: 'Nummer',
  thousandCrowns: 'I hele tusind DKK',
  totalYear: 'Total {{maintenance_plan_length}} år',
  property: 'Ejendom',
  buildingPart: 'Bygningsdel',
  rawPredictions: 'Rå forudsigelser',
  rawRegistrations: 'Rå registreringer',
  conditionOverview: 'Stand overblik',
  multipleProperties: 'Flere ejendomme',
  defect: 'defekt',
  worn: 'nedslidt',
  used: 'brugt',
  good: 'god',
  new: 'ny',
  years: 'år',
  checklist: 'Checkliste',
  description: 'Beskrivelse',
  noRegistrations: 'Tilføj en registrering for at komme i gang',
  home: 'Hjem',
  data: 'Data',
  properties: 'Ejendomme',
  users: 'Brugere',
  registrations: 'Registreringer',
  map: 'Kort',
  profile: 'Profil',
  logOut: 'Log ud',
  logIn: 'Log ind',
  logInWithPassword: 'Log ind med password',
  logInWithSSO: 'Log ind med SSO',
  or: 'Eller',
  setUpSSO: 'Kontakt os for at sætte SSO op for dit firma hvis i ikke allerede har det på: ',
  danish: 'Dansk',
  english: 'Engelsk',
  noPropertiesFound: 'Ingen ejendomme blev fundet',
  propertyNumber: 'Ejendomsnummer',
  name: 'Navn',
  rowsPerPage: 'Rækker pr. side:',
  propertiesSearchPlaceholder: 'Adresse, ejendomsnr., etc...',
  search: 'Søg',
  password: 'Kodeord',
  buildings: 'Bygninger',
  buildingNumber_one: 'Bygningsnummer',
  buildingNumber_other: 'Bygningsnumre',
  address: 'Adresse',
  application: 'Anvendelse',
  condition: 'Stand',
  bfeNumber: 'BFE nummer',
  department: 'Afdeling',
  links: 'Links',
  caretaker: 'Vicevært',
  registrationsForProperty: 'Registreringer',
  add: 'Tilføj',
  start: 'Start',
  overallCondition: 'Overordnet stand',
  overallConditionOfProperty: 'Overordnet stand af ejendom',
  maintenancePlan: 'Vedligeholdelsesplan',
  maintenanceNextXYears: 'Vedligehold for {{start_year}} - {{end_year}}',
  confidence: 'Sikkerhed',
  overallConfidence: 'Overordnet sikkerhed',
  seeMore: 'Se mere',
  createRegistration: 'Opret registrering',
  cancel: 'Annuller',
  create: 'Opret',
  createAndClose: 'Opret og luk',
  createAndContinue: 'Videre til udførsel',
  buildingPartsToRegister: 'Bygningsdele der skal registreres',
  checkAll: 'Vælg alle',
  uncheckAll: 'Fravælg alle',
  building: 'Bygning',
  buildingsToRegister: 'Vælg bygninger der skal registreres på',
  message: 'Skriv en besked til modtageren af registreringen',
  writeAMessage: 'Skriv en besked',
  to: 'til',
  whoShouldRegister: 'Hvem skal lave registreringen?',
  existingUser: 'Eksisterende bruger',
  waiting: 'Afventer',
  wantToRegisterOnMoreBuildings: 'Vil du registrere på flere bygninger ad gangen?',
  startRegistration: 'Start registrering',
  finish: 'Færdig',
  saveForLater: 'Gem til senere',
  overallConditionPart: 'Overordnet stand på',
  registerLifespan: 'Registrer levetid',
  addActivity: 'Tilføj aktivitet',
  addPicture: 'Tag billede',
  addComment: 'Tilføj kommentar',
  editComment: 'Rediger kommentar',
  year_one: 'År',
  year_other: 'År',
  continue: 'Fortsæt',
  doesntExist: 'Eksisterer ikke',
  notInspected: 'Ikke inspiceret',
  lastUpdated: 'Sidst opdateret',
  constructive: 'Oprettende',
  preventive: 'Forebyggende',
  remedial: 'Afhjælpende',
  improvement: 'Forbedring',
  price: 'Pris',
  priceInThousands: 'Pris i hele tusinder',
  comment: 'Kommentar',
  frequence: 'Frekvens',
  everyXYear: 'Hvert {{year}}. år',
  noFrequence: 'Ingen frekvens',
  distributeOverYears: 'Fordel over en årrække',
  endYear: 'Slut år',
  totalPrice: 'Total pris',
  pricePerYear: 'Pris per år',
  numberOfYears: 'Antal år',
  thousandSeparator: '.',
  decimalSeparator: ',',
  save: 'Gem',
  startingYear: 'Starter i år',
  delete: 'Slet',
  confirm: 'Bekræft',
  deleteThisActivity: 'Vil du slette denne aktivitet?',
  writeAComment: 'Skriv en kommentar',
  autofill: 'Autofyld',
  generating: 'Genererer',
  generatingChatGPTError: 'Fejl, prøv igen',
  viewingDataForProperties: 'Ejendomme du ser data for',
  viewingDataForBuildings: 'Ejendomme du ser data for',
  lifespan: 'Levetid',
  constructionYear: 'Byggeår',
  postalCode: 'Postnummer',
  organization: 'Organisation',
  organizations: 'Organisationer',
  filter: 'Filtrer',
  filterPropertiesAndBuildings: 'Filtrer ejendomme og bygninger',
  resetFilters: 'Nulstil filtre',
  languages: {
    da: 'Dansk',
    en: 'Engelsk',
  },
  themes: {
    light: 'Lyst',
    dark: 'Mørkt',
  },
  language: 'Sprog',
  theme: 'Tema',
  pleaseSelectToSeeCondition: 'Vælg én eller flere bygninger eller ejendomme for at se forudsigelser på tilstand',
  pleaseSelectToSeePlan: 'Vælg én eller flere bygninger eller ejendomme for at se forudsigelser på vedligeholdsplan',
  profileNotFound: 'Profil ikke fundet',
  phoneNumber: 'Telefonnummer',
  email: 'Email',
  code: 'Kode',
  preferences: 'Præferencer',
  userType: 'Brugertype',
  emailTranslations: {
    dear: 'Kære',
    youHaveBeenInvited: 'Du er blevet inviteret til at gennemgå',
    onBehalfOf: 'på vegne af',
    wantYouToLookOnFollowing: 'Vi vil gerne have dig til at kigge på følgende bygningsdele:',
    hasAddedMessage: 'har tilføjet følgende besked:',
    getOverview: 'Du kan starte med at få dig et overblik over ejendommen ved at klikke på knappen herunder:',
    getOverviewButton: 'Få overblik',
    startRegistration: 'Når du er på ejendommen, kan du starte registreringen ved at klikke på knappen herunder:',
    startRegistrationButton: 'Start registrering',
    anyQuestions: 'Hvis du har nogen spørgsmål, er du velkommen til at svare tilbage på denne mail.',
    bestRegards: 'Bedste hilsner',
    goToProperty: 'Gå til ejendom',
    reportSentToEmail: 'Her er din rapport!',
  },
  title: 'Titel',
  created: 'Oprettet',
  allParts: 'Alle bygningsdele',
  addBuildingPart: 'Tilføj bygningsdel',
  addBuildingPartToBuilding: 'Tilføj bygningsdele til valgt bygning på {{building}}',
  addBuildingPartToOrganization: 'Tilføj bygningsdele til alle bygninger på denne ejendom',
  addBuildingPartDescription: 'Du kan vælge en eller flere bygningsdele',
  selectBuildingParts: 'Vælg bygningsdele',
  loadingDotDotDot: 'Indlæser...',
  back: 'Tilbage',
  undo: 'Fortryd',
  markedAsDoesntExist: 'Markeret som "Eksisterer ikke"',
  markedAsNotInspected: 'Markeret som "Ikke inspiceret"',
  remainingLifespan: 'Resterende levetid',
  enteredData: 'Du har indtastet data, som vil blive slettet hvis du gør dette.',
  confirmNotInspected: 'Vil du markere denne bygningsdel som "Ikke inspiceret"?',
  confirmDoesntExist: 'Vil du markere denne bygningsdel som "Eksisterer ikke"?',
  images: 'Billeder',
  required: 'påkrævet',
  deleteImage: 'Vil du slette dette billede?',
  activities: 'Aktiviteter',
  addProperty: 'Tilføj ejendom',
  searchProperties: 'Søg ejendomme',
  startTypingToSearch: 'Begynd at skrive for at søge',
  searchPropertiesPlaceholder: 'BFE nummer, adresse, afdeling...',
  noAddressesFound: 'Ingen adresser fundet',
  goToProperty: 'Gå til ejendom',
  parentOrganization: 'Overordnet organisation',
  company: 'Selskab',
  outside: 'Ude',
  inside: 'Inde',
  download: 'Download',
  downloadMaintenancePlan: 'Download rapport',
  total: 'Total',
  total2: 'Samlet',
  openAll: 'Åbn alle',
  closeAll: 'Luk alle',
  planMaintenance: 'Planlæg Vedligehold',
  estimatedMaintenance: 'Estimeret Vedligehold',
  suggestedActivities: 'Foreslåede aktiviteter',
  chooseOnlyOne: 'Vælg kun én',
  comparison: 'Sammenligning',
  editActivity: 'Rediger aktivitet',
  planned: 'Planlagt',
  collision: 'Kollision',
  postpone: 'Udsættelse',
  consolidate: 'Sammenlægning',
  constructionSite: 'Byggeplads',
  tipsAndTricks: 'Tips & Tricks',
  tipsDistrictHeating: 'Tjek udrulning af fjernvarme',
  tipsDistrictHeatingDialogContent:
    "Har du tjekket planen for udrulning af fjernvarme i området?<br/>Hold øje med opdateringer på <a rel='noopener noreferrer' target='_blank' href='https://kort.plandata.dk/'>Plan- og Landdistriktsstyrelsen's hjemmeside</a>.",
  tipsConsolidateScaffolding: 'Flyt aktiviteter til {{year}} for at spare på omkostninger',
  tipsConsolidateScaffoldingDialog1: 'Du har en række oprettende aktiviteter spredt henover en længere periode.',
  tipsConsolidateScaffoldingDialog2:
    'Oprettende vedligehold på disse bygningsdele kræver ofte stillads mm. og du kan potentielt spare på udgifter ved at flytte aktiviterne tættere sammen.  Vi anbefaler år {{year}}, samt tilføjning af forebyggende vedlighehold hvor det er nødvendigt.',
  tipsPostponeTerrainBlocking: 'Udskyd aktivitet for at undgå skade på terræn',
  tipsPostponeterrainBlockingDialog1: 'Din plan indeholder renovering af terræn(',
  tipsPostponeterrainBlockingDialog2: ', {{activityYear}}), efterfulgt af arbejde der formentlig kræver byggeplads.',
  tipsPostponeterrainBlockingDialog3: 'Overvej at udskyde terrænarbejdet til efter år {{year}}.',
  unplannedActivities: 'Ikke planlagte aktiviteter',
  unplannedActivity: 'Ikke planlagt aktivitet',
  unplannedActivityLegendTooltip: 'Dine endnu-ikke planlagte aktiviter',
  predictedActivity: 'Foreslåede aktiviteter',
  predictedActivityLegendTooltip: 'Forudsete omkostninger fra vores AI',
  collisionLegendTooltip: 'Forskellen mellem din planlagte aktivitet og den forudsete omkostning fra vores AI',
  tipsAndTricksLegendTooltip: 'Forslag du kan tage op til overvejelse mens du udarbejder din plan',
  preventiveMaintenance: 'Forebyggende vedligehold',
  constructiveMaintenance: 'Oprettende vedligehold',
  differenceBetweenPlannedAndPredicted: 'Forskellen mellem planlagt og forudsigelse',
  thousandShorthand: 't',
  overMaintenanceBy: 'Overvedligehold på',
  underMaintenanceBy: 'Undervedligehold på',
  improvements: 'Forbedringer',
  creatingActivity: 'Opretter aktivitet',
  activityCreated: 'Aktivitet oprettet',
  plannedMaintenance: 'Planlagt vedligehold',
  createMaintenancePlan: 'Opret ny vedligeholdelsesplan',
  duplicateMaintenancePlan: 'Dupliker plan',
  dontDuplicate: 'Dupliker ikke',
  maintenancePlanName: 'Vedligeholdelsesplan navn',
  maintenancePlanStatus: 'Status',
  updateStatus: 'Opdater status',
  createNew: 'Lav ny plan',
  continueMaintenancePlan: 'Fortsæt med igangværende vedligeholdelsesplan.',
  noPlansFound: 'Ingen planer fundet. Opret en plan for at komme igang.',
  open: 'Åben',
  show: 'Vis',
  approved: 'Godkendt',
  sentToExecution: 'Sendt til udførsel',
  reopen: 'Genåben',
  approve: 'Godkend',
  sendToExecution: 'Send til udførsel',
  confirmSendToExecution:
    'Er du sikker på at du vil sende til udførsel? Planer der er sendt til udførsel kan ikke genåbnes. Kun en plan om året må sendes til udførsel.',
  maintenancePlanNotFoundError: 'Vedligeholdelsesplan ikke fundet',
  maintenancePlanStatusUpdated: 'Status opdateret',
  maintenancePlanNameEmpty: 'Navn skal udfyldes',
  maintenancePlanUnplannedActivitiesRemainingError:
    'Planer med ikke planlagte aktiviteter kan ikke godkendes eller sendes til udførsel',
  duplicateStatusApprovedError: "Kun en plan per år kan have status 'Godkendt'.",
  duplicateStatusSentToExecutionError: "Kun en plan per år kan have status 'Sendt til udførsel'.",
  conditionAssessmentReport: {
    conditionAssessmentReport: 'Tilstandsvurdering',
    date: 'Dato',
    approvedBy: 'Godkendt af',
    nameOfApprover: 'Navn på godkender',
    tableOfContents: 'Indholdsfortegnelse',
    preFace: 'Forord',
    preFaceText1:
      'Denne tilstandsvurdering er en helhedsorientering over standen på ejendommen og en gennemgang af de enkelte bygningsdele.',
    preFaceText2:
      'Tilstandsvurderingen er delvist opbygget af en kunstig intelligens der baseret på viden om store dele af bygningsmassen i Danmark giver anvisninger på stand, levetid samt vedligeholdsbudget. Denne er komplimenteret af en inspektion og en byggeteknisk vurdering.',
    preFaceText3:
      'Rapporten indeholder en vurdering af standen på bygningsdelsniveau samt anvisninger på hvilke levetidsbetragtninger, som er relevante for de væsentligste oprettende vedligeholdelsesaktiviteter.',
    preFaceText4:
      'Planen omfatter desuden et overslag over de udgifter, der er nødvendige for det anbefalede vedligehold.',
    preFaceText5:
      'Vedligeholdelsesplanen er således et redskab, som giver jer et bedre overblik over ejendommen, og hvilke økonomiske disponeringer I skal foretage. Det kan sikre jer rentable prioriteringer, der kan åbne op for muligheder for nye spændende tiltag på jeres ejendom.',
    definitions: 'Definitioner',
    conclusion: 'Konklusion',
    conclusionText1:
      'En opsamling og konklusion på standen af ejendommen, bygningsdele, forslag til økonomi prioritet og benchmark. Formålet er at give læser et hurtigt overblik over rapportens væsentligste fund.',
    condition: 'Stand',
    conditionText1:
      'For hver bygningsdel vil der være en tilstandsvurderingen. Standen af den enkelte bygningsdel vurderes på en <skala> fra 1 – 5. Et værende ’Nyt’ og fem værende ’Nedslidt’. Standen er et udtryk for i hvilken grad bygningsdelen kræver vedligehold. Standen kan være en AI forudsigelse eller baseret på en inspektion.',
    confidence: 'Sikkerhed',
    confidenceText1:
      'Dette udtrykker en procentsats som viser i hvor høj en grad den kunstige intelligens mener sig sikker på sin forudsigelse. Dette kan også indeholde en datostempel, når der har været gennemført en manuel inspektion.',
    priority: 'Prioritet',
    priorityText1:
      'Dette felt udtrykker et forslag til en prioritet, baseret på bygningsdelens indflydelse på bygningens generelle sundhed samt øvrige bygningsdele. Du kan have bygningsdele som er i dårlig stand uden det har påvirkning på bygningens overordnede sundhed og på virkning på andre bygningsdele.',
    remainingLifespan: 'Restlevetid',
    remainingLifespanText1:
      'Restlevetid fortæller hvor lang levetid bygningsdelen har før denne bør udskiftet, ud fra forudsætningen om der udføres korrekt forebyggende vedligehold. Restlevetid kan være en AI forudsigelse eller baseret på en vurdering foretaget ved besigtigelse.',
    maintenanceActivities: 'Vedligeholdelsesaktiviteter',
    maintenanceActivitiesText1:
      'Rapporten indeholder også et forslag til budget med vedligeholdsaktiviteter. Aktiviteterne kan være en AI forudsigelse eller baseret på en inspektion. Disse aktiviteter kan have forskellige karakterer ',
    maintenanceActivitiesRemedial: 'Afhjælpende: Akut vedligehold af bygningsdel',
    maintenanceActivitiesPreventive:
      'Forebyggende: Løbende vedligehold der tilsikre sundheden af bygningsdelen og levetidsforlænger',
    maintenanceActivitiesConstructive: 'Oprettende: Dette er en renovering eller udskiftning af bygningsdelen',
    maintenanceActivitiesImprovement:
      'Forbedring: Dette er investeringsaktiviteter der forbedrer ejendommen for lejere',
    benchmark: 'Benchmark',
    benchmarkText1:
      'Dette er en sammenligning af standen med sammenlignelige ejendomme (opførselsår, materiale, bygningstype).',
    prices: 'Priser',
    pricesText1:
      'Priserne baserer sig på stort datasæt med erfaringstal. Disse erfaringstal er både tidligere planlagt om faktisk realiserede omkostninger på alle typer af vedligehold. Mængderne er taget ud fra data på ejendommen eller alternativt et augmenteret datasæt fra AI modellen. Priser kan yderligere være overslagspriser på aktiviteter oprettet ved inspektion.',
    foto: 'Foto',
    fotoText1:
      'Bygningsdele hvor der har været en inspektion er der taget billeder af relevante bygningsdele. Billederne indgår som illustration til bemærkningerne til de enkelte bygningsdele.',
    fotoText2:
      'Det forudsættes, at billedmaterialet er taget og fremlagt med det grundlæggende formål at illustrere afdelingens generelle tilstand og specielle problemer. Diskretion og agtelse for privatlivets fred vil blive respekteret (det kan f.eks. også betyde, at det ikke altid er muligt at besigtige et konkret lejemål eller en konkret bygningsdel).',
    maintenancePlan: 'Vedligeholdelsesplan',
    maintenancePlanText1:
      'Det gældende langtidsbudget er vedlagt, så læseren kan se budgetgrundlaget for de anbefa- linger, som rapporten indeholder.',
    propertyOverview: 'Ejendomsoversigt',
    organization: 'Organisation',
    propertyDesignation: 'Ejendomsbetegnelse',
    address: 'Adresse',
    numberOfBuildings: 'Antal bygninger',
    dateOfApproval: 'Dato godkendt',
    link: 'Link',
    bfeNumber: 'BFE Nummer',
    id: 'id',
    municipality: 'Kommune',
    masterData: 'Stamdata',
    buildings: 'Bygninger',
    buildingOverview: 'Bygningsoversigt',
    buildingId: 'Bygningsid',
    usage: 'Anvendelse',
    page: 'Side',
    of: 'af',
    conditionOverview: 'Overblik stand',
    buildingPart: 'Bygningsdel',
    economy: 'Økonomi',
    additionalInformation: 'Yderligere information',
    primaryMaterial: 'Primære bygningsmateriale',
    comments: 'Kommentarer',
    preventiveMaintenance: 'Forebyggende vedligehold',
    remedialMaintenance: 'Afhjælpende vedligehold',
    constructiveMaintenance: 'Oprettende vedligehold',
    improvements: 'Forbedringer',
    image: 'Billede',
    amountOfActivities: 'Antal aktiviteter',
    noConditionData: 'Ingen data for stand.',
    years: 'år',
    maintenanceCost: 'Vedligeholdelsesomkostninger',
    yearCostInWholeNumbers: 'Årstal / beløb i hele kr.',
  },
  notFoundPageDescription: 'Oops! Vi kunne ikke finde siden du ledte efter.',
  warningTextDeleteCustomerWithSuperUser:
    'Denne organisation har superbrugere. Sletning af denne organisation vil slette alle superbrugere.',
} as const; //TODO: add conditionAssessmentReport to english translation

export default translation;
